<template>
  <Dialog
    v-model="useDialogStore().quoteEdit.isOpen"
    size="small-hug"
    @open="onOpen"
    @close="onClose"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd244')"
        :show-close="true"
        @close="close"
      />
    </template>

    <div
      v-if="isLoading"
      v-loading="true"
      class="h-80 w-full"
    />
    <div v-else>
      <div class="rounded-xl bg-gray-100 overflow-hidden p-5 mb-2">
        <div v-if="form.workspace">
          <div class="flex items-start justify-between mb-3">
            <Thumbnail
              :image="form.workspace?.identity?.logo"
              :name="form.workspace?.name"
              size="lg"
              fit-content
              class="bg-white"
            />

            <div>
              <QuoteStatusBadge
                :quote="form"
              />
            </div>
          </div>

          <div class="text-xs text-gray-500">
            {{ form.workspace?.name }}
          </div>

          <h3 class="text-sm font-semibold flex items-center gap-px">
            <span>
              {{ form.title }}
            </span>
            <Button
              type="regular-text"
              size="small"
              content-icon="edit-03"
              @click="() => useDialogStore().open('quoteEditTitle', {
                id: useDialogStore().quoteEdit.id,
                workspaceDomain: useDialogStore().quoteEdit.workspaceDomain,
                onSubmit: onOpen,
              })"
            />
          </h3>

          <div class="mb-3 text-xs text-gray-500 flex items-center">
            <a
              :href="`https://attendu.pipedrive.com/deal/${form.pipedrive?.deal}`"
              class="flex items-center gap-1 hover:text-gray-900"
              target="_blank"
            >
              Pipedrive
              <Icon
                name="link-external-02"
                class="w-3 h-3"
              />
            </a>
            <button
              class="underline hover:text-gray-900 ml-2"
              @click="() => useDialogStore().open('quoteEditPipedrive', {
                id: useDialogStore().quoteEdit.id,
                workspaceDomain: useDialogStore().quoteEdit.workspaceDomain,
                onSubmit: onOpen,
              })"
            >
              Edit
            </button>
          </div>

          <div class="w-full flex gap-3">
            <TextInput
              :model-value="String(publicQuoteLink || '')"
              readonly
            />

            <Button
              type="regular-outline"
              icon-left="copy-03"
              @click="() => {
                useClipboard().copy(publicQuoteLink);
                useToast(useT('bcd286'));
              }"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-end text-right mb-4 text-xs text-gray-400">
        {{ useT('bcd287') }} {{ form.creator?.name }}
        {{ getFormattedDate(form.createdAt, {
          lang: useAuthStore().user?.locale,
          format: 'll',
        }) }}
      </div>

      <Form
        ref="formRef"
        :model="form"
        :rules="rules"
        @submit="onSubmit"
      >
        <h3 class="font-semibold text-base mb-4">
          {{ useT('bcd248') }}
        </h3>

        <QuoteFormProduct
          v-model:form="form"
          :workspace="form.workspace"
        />

        <div class="border-t border-gray-200 mt-4 pt-4">
          <Button
            v-if="form.isArchived"
            icon-left="refresh-ccw-01"
            :loading="isUnarchiving"
            @click="handleUnarchiveQuote"
          >
            {{ useT('bcd312') }}
          </Button>
          <Button
            v-else
            icon-left="archive"
            :loading="isArchiving"
            @click="handleArchiveQuote"
          >
            {{ useT('bcd259') }}
          </Button>
        </div>
      </Form>
    </div>

    <template
      v-if="!isLoading"
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd15')"
        :text-cancel="useT('bcd205')"
        align="left"
        :loading="isSubmitting"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('quoteEdit')"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const form = ref({
});

const rules = computed(() => ({
  'product.category': [useFormRules().required],
  'pipedrive.deal': [useFormRules().required],
  ...(form.value?.product?.category === 'planSubscription'
    ? {
      'product.key': [useFormRules().required],
    }
    : {}),
}));

const isArchiving = ref(false);
const isUnarchiving = ref(false);

const isLoading = ref(false);
const isSubmitting = ref(false);
const formRef = ref(null);

// Computed
const publicQuoteLink = computed(() => `${useRequestURL().protocol}//${useDialogStore().quoteEdit.workspaceDomain}.${useRuntimeConfig().public.interfaceDomain}/quote/${form.value._id}`);

// Methods
const onOpen = async() => {
  isLoading.value = true;

  const quote = await useQuotesStore().fetchQuote({
    quoteId: useDialogStore().quoteEdit.id,
    workspaceDomain: useDialogStore().quoteEdit.workspaceDomain,
  });

  if (!quote) {
    useDialogStore().close('quoteEdit');
    return;
  }

  form.value = _cloneDeep(quote);

  isLoading.value = false;

  useRouter().push({
    query: {
      quote: useDialogStore().quoteEdit.id,
      workspace: useDialogStore().quoteEdit.workspaceDomain,
    },
  });
};

const onClose = () => {
  form.value = {};
  useDialogStore().quoteEdit.item = null;

  useRouter().push({
    query: {
      ...useRoute().query,
      quote: undefined,
      workspace: undefined,
    },
  });
};

const saveQuote = async() => {
  isSubmitting.value = true;

  const response = await useQuotesStore().update({
    quoteId: form.value._id,
    body: {
      ...form.value,
      workspace: form.value.workspace?.domain,
    },
  });

  isSubmitting.value = false;

  if (response) {
    useToast(useT('bcd138'));
    useDialogStore().quoteEdit.onSubmit(response);
  }

  return response;
};

const onSubmit = async() => {
  const response = await saveQuote();
  if (response) {
    useDialogStore().close('quoteEdit');
  }
};

const handleArchiveQuote = () => {
  useModalStore().open({
    type: 'danger',
    title: useT('bcd139'),
    description: useT('bcd140'),
    submitButtonText: useT('bcd141'),
    onSubmit: async() => {
      isArchiving.value = true;

      const response = await useQuotesStore().archive({
        quoteId: useDialogStore().quoteEdit.id,
        workspaceDomain: useDialogStore().quoteEdit.workspaceDomain,
      });

      isArchiving.value = false;

      if (response) {
        useToast(useT('bcd143'));
        useDialogStore().quoteEdit.onSubmit();
        useDialogStore().close('quoteEdit');
      }
    },
  });
};

const handleUnarchiveQuote = () => {
  useModalStore().open({
    type: 'danger',
    title: useT('bcd313'),
    description: useT('bcd314'),
    submitButtonText: useT('bcd315'),
    onSubmit: async() => {
      isUnarchiving.value = true;

      const response = await useQuotesStore().unarchive({
        quoteId: useDialogStore().quoteEdit.id,
        workspaceDomain: useDialogStore().quoteEdit.workspaceDomain,
      });

      isUnarchiving.value = false;

      if (response) {
        useToast(useT('bcd316'));
        useDialogStore().quoteEdit.onSubmit();
        useDialogStore().close('quoteEdit');
      }
    },
  });
};

// Lifecycle
onMounted(() => {
  if (useRoute()?.query?.quote) {
    useDialogStore().open('quoteEdit', {
      id: useRoute().query?.quote,
      workspaceDomain: useRoute().query?.workspace,
    });
  }
});
</script>
