<template>
  <Dialog
    v-model="useDialogStore().userCreate.isOpen"
    size="small-hug"
    @open="onOpen"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd17')"
        :show-close="true"
        @close="close"
      />
    </template>

    <Form
      ref="formRef"
      :model="form"
      :rules="rules"
      @submit="onSubmit"
    >
      <InputBlock
        :label="useT('bcd9')"
        prop="fullName"
      >
        <TextInput
          v-model="form.fullName"
          name="fullName"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd206')"
        prop="email"
      >
        <TextInput
          v-model="form.email"
          type="email"
          name="email"
          :placeholder="useT('bcd206')"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd307')"
        prop="role"
      >
        <Select
          v-model="form.role"
          name="role"
          :options="useUsersStore().roles.map((role) => ({ id: role, text: _upperFirst(role) }))"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd10')"
        prop="password"
      >
        <TextInput
          v-model="form.password"
          type="password"
          name="password"
          autocomplete="new-password"
          data-private
          :placeholder="useT('bcd21')"
        />
        <el-collapse-transition>
          <UserPasswordStrength
            :password="form.password"
            :rules="rules.password"
          />
        </el-collapse-transition>
      </InputBlock>
    </Form>

    <template
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd25')"
        :text-cancel="useT('bcd205')"
        align="right"
        :loading="isLoading"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('userCreate')"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const form = ref({
  fullName: '',
  email: '',
  role: '',
  password: '',
});

const rules = {
  email: [
    useFormRules().required,
    useFormRules().email,
  ],
  role: [useFormRules().required],
  password: useFormRules().password,
};

const isLoading = ref(false);
const formRef = ref(null);

// Methods
const onOpen = () => {
  form.value = {
    fullName: '',
    email: '',
    role: '',
    password: '',
  };
};

const onSubmit = async() => {
  isLoading.value = true;

  const response = await useUsersStore().sendUserInvite(form.value);

  isLoading.value = false;

  if (response) {
    useToast(useT('bcd45'));
    useDialogStore().close('userCreate');
    useDialogStore().userCreate.onSubmit(response);
  }
};
</script>
