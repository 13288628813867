<template>
  <div class="h-full relative">
    <DialogHeader
      :title="useT('bcd8')"
      size="large"
      show-close
      class="absolute top-0 left-0 z-20 bg-white w-full rounded-tr-[var(--el-dialog-border-radius)]"
      @close="useDialogStore().close('settings')"
    />
    <div class="h-full overflow-auto">
      <div class="py-16">
        <div class="flex justify-between p-6">
          <TextInput
            v-model="searchQuery"
            :placeholder="useT('bcd16')"
            class="w-80"
            search
          />

          <Button
            type="primary"
            icon-left="plus"
            data-cy="invite-user"
            @click="useDialogStore().open('userCreate', { onSubmit: () => fetchUsers() })"
          >
            {{ useT('bcd17') }}
          </Button>
        </div>

        <div class="px-6 w-full">
          <el-table
            v-if="!isLoading"
            :data="usersFiltered"
            row-key="_id"
            @row-click="(rowData) => useDialogStore().open('userEdit', { user: rowData, onSubmit: () => fetchUsers() })"
          >
            <el-table-column
              prop="user"
              :label="useT('bcd18')"
              sortable
            >
              <template #default="scope">
                <div class="flex items-center gap-3">
                  <Avatar
                    :image="scope.row?.profilePhoto"
                    :name="scope.row?.fullName"
                    size="md"
                  />
                  <div class="text-sm">
                    <div class="flex items-center gap-2">
                      <span
                        v-if="scope.row.fullName"
                        class="text-gray-900 font-medium"
                      >
                        {{ scope.row.fullName }}
                      </span>
                      <Badge
                        :text="_upperFirst(scope.row.role)"
                        type="neutral"
                      />
                    </div>
                    <div class="text-gray-600">
                      {{ scope.row.email }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label=""
              width="200"
              align="right"
            >
              <template #default="scope">
                <div class="flex justify-end">
                  <Button
                    content-icon="chevron-right"
                    @click="() => scope.row._id"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div
            v-show="isLoading"
            v-loading="isLoading"
            class="w-full h-48"
          />
          <div
            v-if="usersFiltered.length"
            class="el-table__counter"
          >
            {{ useT('bcd4', { n: usersFiltered.length }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Refs
const isLoading = ref(false);
const searchQuery = ref('');

// Computed
const usersFiltered = computed(() => filterSearchInFields({
  items: useUsersStore().users,
  fields: ['fullName', 'email'],
  query: searchQuery.value,
}));

// Methods
const fetchUsers = () => {
  isLoading.value = true;
  useUsersStore().fetchUsers().then(() => isLoading.value = false);
};

// Lifecycle
onMounted(() => fetchUsers());
</script>
