export const usePlansStore = defineStore('plans', {
  state: () => {
    return {
      total: 0,
      currentPage: 1,
      perPage: 100,
      plans: [],
    };
  },

  actions: {
    async fetchPlans(params) {
      const response = await useApi.get('/plans', { params }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd270'));
      });

      if (response) {
        this.total = response.total;
        this.plans = response.results;
      }
    },

    async fetchPlan({ planId, workspaceDomain }) {
      const response = await useApi.get(`/plans/${planId}`, {
        params: {
          workspace: workspaceDomain,
        },
      }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd270'));
      });

      return response;
    },

    async create(body) {
      const response = await useApi.post('/plans', { body }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd270'));
      });

      return response;
    },

    async update({ planId, body }) {
      const response = await useApi.patch(`/plans/${planId}`, { body }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd270'));
      });

      return response;
    },

    async deactivate({ planId, workspaceDomain }) {
      const response = await useApi.post(`/plans/${planId}/deactivate`, {
        params: {
          workspace: workspaceDomain,
        },
      }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd270'));
      });

      return response;
    },
  },

  getters: {
  },
});
