<template>
  <Dialog
    v-model="useDialogStore().quoteCreate.isOpen"
    size="small-hug"
    @open="onOpen"
    @close="onClose"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd275')"
        :show-close="true"
        @close="close"
      />
    </template>

    <div
      v-if="isLoading"
      v-loading="true"
      class="h-80 w-full"
    />
    <div v-else>
      <Form
        ref="formRef"
        :model="form"
        :rules="rules"
        @submit="onSubmit"
      >
        <div>
          <h3 class="font-semibold text-base mb-4">
            {{ useT('bcd276') }}
          </h3>

          <InputBlock
            :label="useT('bcd277')"
            prop="workspace"
            direction="horizontal"
          >
            <Select
              v-model="form.workspace"
              v-loading="isWorkspacesLoading"
              :options="useWorkspacesStore().workspaces.map((workspace) => ({
                thumbnail: workspace.identity?.logo,
                id: workspace.domain,
                text: workspace.name || workspace.domain,
              }))"
              filterable
            />
          </InputBlock>

          <InputBlock
            :label="useT('bcd278')"
            prop="title"
            direction="horizontal"
          >
            <TextInput
              v-model="form.title"
              :placeholder="useT('bcd279')"
            />
          </InputBlock>

          <QuoteFormPipedriveDeal
            v-model:form="form"
            direction="horizontal"
          />
        </div>

        <div class="border-t border-gray-200 mt-6 pt-3">
          <h3 class="font-semibold text-base mb-4">
            {{ useT('bcd248') }}
          </h3>

          <QuoteFormProduct
            v-model:form="form"
            :workspace="useWorkspacesStore().workspaces.find((workspace) => workspace.domain === form.workspace)"
          />
        </div>
      </Form>
    </div>

    <template
      v-if="!isLoading"
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd275')"
        :text-cancel="useT('bcd205')"
        align="right"
        :loading="isSubmitting"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('quoteCreate')"
      />
    </template>
  </Dialog>
</template>

<script setup>
const FORM_BASE = {
  product: {
    category: 'planSingleUse',
    key: 'single_use_basic',
    data: {
      tier: 'basic',
      maxCapacity: 200,
    },
  },
};

// Refs
const form = ref(_cloneDeep(FORM_BASE));

const rules = computed(() => ({
  workspace: [useFormRules().required],
  title: [useFormRules().required],
  'product.category': [useFormRules().required],
  'pipedrive.deal': [useFormRules().required],
  ...(form.product?.category === 'planSubscription'
    ? {
      'product.key': [useFormRules().required],
    }
    : {}),
}));

const isLoading = ref(false);
const isSubmitting = ref(false);
const formRef = ref(null);
const isWorkspacesLoading = ref(false);

// Computed

// Methods
const onOpen = async() => {
  isLoading.value = true;
  await useWorkspacesStore().fetchWorkspaces();
  isLoading.value = false;
};

const onClose = () => {
  form.value = _cloneDeep(FORM_BASE);
};

const savePlan = async() => {
  isSubmitting.value = true;

  const response = await useQuotesStore().create(form.value);

  isSubmitting.value = false;

  if (response) {
    useToast(useT('bcd302'));
    useDialogStore().quoteCreate.onSubmit(response);
  }

  return response;
};

const onSubmit = async() => {
  const response = await savePlan();
  if (response) {
    useDialogStore().close('quoteCreate');
  }
};
</script>
