<template>
  <div
    class="shadow-sm border border-gray-200 rounded-lg"
  >
    <div class="flex items-center w-full p-3">
      <Avatar
        size="md"
        type="square"
        :icon="props.plan.type === 'subscription' ? 'infinity' : 'package'"
        class="border-none"
        :class="props.showStatus && plan.status !== 'active' ? '' : 'bg-green-100 [&_svg]:text-green-500'"
      />
      <div class="flex-1 flex items-center">
        <div class="mx-3">
          <div class="text-xs text-gray-600">
            {{ props.plan.type === 'singleUse' ? useT('bcd245') : useT('bcd246') }}
          </div>
          <div class="text-sm font-medium text-gray-900">
            {{ _capitalize(props.plan.config.tier) }}
          </div>
        </div>
      </div>
      <div class="flex items-center">
        <div class="text-right px-3">
          <div class="text-gray-600 text-sm">
            {{ useT('bcd296', { value: props.plan.config.maxCapacity }) }}
          </div>
          <div class="text-gray-400 text-xs whitespace-nowrap">
            {{ useT('bcd164') }}
            {{ getFormattedDate(props.plan.createdAt, {
              lang: useAuthStore().user?.locale,
              format: 'll • HH:mm',
            }) }}
          </div>
        </div>
      </div>
      <div class="pl-3 w-28 flex justify-end">
        <Button
          v-if="props.plan._id === useDialogStore().eventChangePlan.event?.plan"
          type="primary"
          size="small"
          disabled
        >
          {{ useT('bcd228') }}
        </Button>
        <Button
          v-else
          :loading="isLoading"
          type="primary"
          size="small"
          @click="() => useModalStore().open({
            type: 'success',
            title: useT('bcd225'),
            description: useT('bcd226'),
            submitButtonText: useT('bcd83'),
            cancelButtonText: useT('bcd205'),
            onSubmit: () => changePlan(),
          })"
        >
          {{ useT('bcd227') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits('plan-changed');

// Refs
const isLoading = ref(false);

// Methods
const changePlan = async() => {
  isLoading.value = true;
  const response = await useEventsStore().changePlan({
    eventId: useDialogStore().eventChangePlan.event?._id,
    planId: props.plan._id,
    workspaceDomain: useDialogStore().eventChangePlan.workspaceDomain,
  });
  isLoading.value = false;

  if (response) {
    emit('plan-changed');
  }
};
</script>
