<template>
  <div>
    <template v-if="useAuthStore().isLoggedIn">
      <MainHeader />
      <div class="min-h-screen pt-14">
        <slot />
      </div>

      <Modal />
      <MainSettingsDialog />
      <UserPasswordChangeDialog />
      <UserCreateDialog />
      <UserEditDialog />
      <WorkspaceCreateDialog />
      <QuoteEditDialog />
      <QuoteEditTitleDialog />
      <QuoteEditPipedriveDialog />
      <EventChangePlanDialog />
      <PlansDialog />
      <PlanEditDialog />
      <PlanCreateDialog />
      <QuoteCreateDialog />
    </template>

    <div
      v-else
      v-loading="true"
      class="w-screen h-screen"
    />
  </div>
</template>

<script setup>
// Refs
const i18n = useI18n();

// Computed
const title = computed(() => {
  if (useRoute()?.meta?.title) {
    return `${typeof useRoute().meta.title === 'function' ? useRoute().meta.title() : useRoute().meta.title} | Attendu`;
  }

  return 'Attendu';
});
useSeoMeta({
  title,
  ogTitle: title,
});

// Lifecycle
onMounted(async() => {
  if (useAuthStore().user && !useAuthStore()?.user?.locale) { // save user locale if not set
    await useUsersStore().updateUserMe({
      locale: i18n?.locale?.value,
    });
    await useAuthStore().fetchUser();
  }

  useProductsStore().fetchPrices();
  useProductsStore().fetchSubscriptionConfigs();
});

// Watchers
watch(() => useAuthStore()?.user?.locale, (locale) => { // () => useRoute().path
  if (locale) {
    i18n.setLocale(locale);
    i18n.locale.value = locale;
  }
}, { immediate: true });
</script>
