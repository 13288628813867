<template>
  <Dialog
    v-model="useDialogStore().eventChangePlan.isOpen"
    size="small"
    unbounded
    @open="onOpen"
    @closed="onClosed"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd221')"
        :show-close="true"
        @close="close"
      />
    </template>

    <div
      v-loading="isLoading"
      class="min-h-[5rem] p-6 space-y-3"
    >
      <EventChangePlanItem
        v-for="plan in usePlansStore().plans"
        :key="plan._id"
        :plan="plan"
        @plan-changed="() => {
          useDialogStore().eventChangePlan.onSubmit();
          useDialogStore().close('eventChangePlan');
        }"
      />

      <EmptyState
        v-if="!isLoading && !usePlansStore().plans.length"
        icon="package"
        :title="useT('bcd297')"
      />
    </div>
  </Dialog>
</template>

<script setup>
// Refs
const isLoading = ref(false);

// Methods
const fetchPlans = async() => {
  isLoading.value = true;

  await usePlansStore().fetchPlans({
    workspaceDomain: useDialogStore().eventChangePlan.workspaceDomain,
    status: ['active'],
  });

  isLoading.value = false;
};

const onOpen = async() => {
  await fetchPlans();
};

const onClosed = () => {
  usePlansStore().plans = [];
};
</script>
