<template>
  <Dialog
    v-model="useDialogStore().workspaceCreate.isOpen"
    size="small-hug"
    @open="onOpen"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd165')"
        :show-close="true"
        @close="close"
      />
    </template>

    <Form
      ref="formRef"
      :model="form"
      :rules="rules"
      @submit="onSubmit"
    >
      <InputBlock
        :label="useT('bcd166')"
        prop="domain"
      >
        <TextInput
          v-model="form.domain"
          name="domain"
          :placeholder="useT('bcd167')"
        />
        <div
          v-if="domainFormatted?.length"
          class="text-xs text-gray-500 mt-1 w-full"
        >
          {{ domainFormatted }}.{{ useRuntimeConfig().public.interfaceDomain }}
        </div>
      </InputBlock>

      <InputBlock
        :label="useT('bcd168')"
        prop="email"
      >
        <TextInput
          v-model="form.email"
          type="email"
          name="email"
          :placeholder="useT('bcd74')"
          @keypress="(event) => emailKeyPressHandle(event)"
        />
        <div class="text-xs text-gray-500 mt-1 w-full">
          {{ useT('bcd169') }}
        </div>
      </InputBlock>

      <InputBlock
        v-if="form.email?.length"
        :label="useT('bcd170')"
        prop="locale"
      >
        <Select
          v-model="form.locale"
          name="locale"
          :options="localeOptions"
        />
      </InputBlock>

      <InputBlock
        prop="isCzechAccount"
      >
        <Checkbox
          v-model="form.isCzechAccount"
          name="isCzechAccount"
          :label="useT('bcd308')"
        />
      </InputBlock>

      <InputBlock
        v-if="form.isCzechAccount"
        :label="useT('bcd309')"
        prop="taxNumber"
      >
        <TextInput
          v-model="form.taxNumber"
          name="taxNumber"
          placeholder="24721123"
        />
      </InputBlock>
    </Form>

    <template
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd95')"
        :text-cancel="useT('bcd205')"
        align="right"
        :loading="isLoading"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('workspaceCreate')"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const form = ref({
  domain: '',
  email: '',
  locale: '',
  isCzechAccount: false,
  taxNumber: '',
});

const rules = computed(() => ({
  domain: [
    useFormRules().required,
  ],
  email: [
    useFormRules().email,
  ],
  locale: [
    useFormRules().required,
  ],
  ...(form.value.isCzechAccount ? { taxNumber: [useFormRules().required] } : {}),
}));

const isLoading = ref(false);
const formRef = ref(null);

const localeOptions = ref([
  { id: 'en', text: useT('bcd172'), icon: 'custom/flag-en' },
  { id: 'cs', text: useT('bcd171'), icon: 'custom/flag-cz' },
]);

// Computed
const domainFormatted = computed(() => normalizeString(form.value.domain || '').replaceAll(' ', '').replace(/[^a-zA-Z0-9-]/g, ''));

// Methods
const onOpen = () => {
  form.value = {
    domain: '',
    email: '',
    locale: localeOptions.value[0].id,
    isCzechAccount: false,
    taxNumber: '',
  };
};

const onSubmit = async() => {
  isLoading.value = true;

  const response = await useWorkspacesStore().create({
    domain: domainFormatted.value,
    email: form.value.email,
    locale: form.value.locale,
    isCzechAccount: form.value.isCzechAccount,
    taxNumber: form.value.taxNumber,
  });

  isLoading.value = false;

  if (response) {
    useToast(useT('bcd173'));
    useDialogStore().close('workspaceCreate');
    useDialogStore().workspaceCreate.onSubmit(response);
  }
};

const emailKeyPressHandle = (event) => {
  const regex = /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~/\\|\-=\s]+$/;

  if (!regex.test(event.key) || event.key.trim().length === 0) {
    event.preventDefault();
  }
};
</script>
