<template>
  <Dialog
    v-model="useDialogStore().planEdit.isOpen"
    size="small-hug"
    @open="onOpen"
    @close="onClose"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd271')"
        :show-close="true"
        @close="close"
      >
        <Badge
          v-if="form.status"
          :text="_upperFirst(form.status)"
          :type="statusTypeMap[form.status]"
        />
      </DialogHeader>
    </template>

    <div
      v-if="isLoading"
      v-loading="true"
      class="h-80 w-full"
    />
    <div v-else>
      <Form
        ref="formRef"
        :model="form"
        :rules="rules"
        @submit="onSubmit"
      >
        <InputBlock
          :label="useT('bcd264')"
          prop="type"
          direction="horizontal"
        >
          <RadioGroup
            :model-value="form.type"
            :options="[
              { id: 'singleUse', text: useT('bcd245') },
              { id: 'subscription', text: useT('bcd246' ) },
            ]"
            disabled
            @update:model-value="(value) => _set(form, 'type', value)"
          />
        </InputBlock>
        <InputBlock
          :label="useT('bcd249')"
          prop="config.tier"
          direction="horizontal"
        >
          <Select
            :model-value="form.config?.tier"
            :options="[
              { id: 'basic', text: useT('bcd250') },
              { id: 'professional', text: useT('bcd251') },
              { id: 'enterprise', text: useT('bcd252') },
            ]"
            @update:model-value="(value) => _set(form, 'config.tier', value)"
          />
        </InputBlock>

        <InputBlock
          :label="useT('bcd266')"
          prop="config.maxCapacity"
          direction="horizontal"
        >
          <NumberInput
            :model-value="form.config?.maxCapacity"
            class="w-full"
            :min="0"
            @update:model-value="(value) => _set(form, 'config.maxCapacity', value)"
          />
        </InputBlock>

        <template v-if="form.type === 'subscription'">
          <InputBlock
            :label="useT('bcd254')"
            prop="config.billingPeriod"
            direction="horizontal"
          >
            <Select
              :model-value="form.config?.billingPeriod"
              :options="[
                { id: 'monthly', text: useT('bcd255') },
                { id: 'annual', text: useT('bcd256') },
              ]"
              @update:model-value="(value) => _set(form, 'config.billingPeriod', value)"
            />
          </InputBlock>

          <InputBlock
            :label="useT('bcd295')"
            prop="config.expiringAt"
            direction="horizontal"
          >
            <DatePicker
              v-model="form.config.expiringAt"
              class="w-full"
            />
          </InputBlock>
        </template>

        <Button
          v-if="form.status === 'active'"
          type="danger-text"
          icon-left="trash-01"
          class="mt-5"
          :loading="isDeactivating"
          @click="handleDeactivatePlan"
        >
          {{ useT('bcd288') }}
        </Button>
      </Form>
    </div>

    <template
      v-if="!isLoading"
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd15')"
        :text-cancel="useT('bcd205')"
        align="left"
        :loading="isSubmitting"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('planEdit')"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const form = ref({
});

const rules = {
};

const isLoading = ref(false);
const isSubmitting = ref(false);
const formRef = ref(null);
const isDeactivating = ref(false);
const statusTypeMap = {
  active: 'success',
  expired: 'warning',
  used: 'neutral',
  deactivated: 'critical',
};

// Computed

// Methods
const onOpen = async() => {
  isLoading.value = true;

  const plan = await usePlansStore().fetchPlan({
    planId: useDialogStore().planEdit.id,
    workspaceDomain: useDialogStore().planEdit.workspaceDomain,
  });

  if (!plan) {
    useDialogStore().close('planEdit');
    return;
  }

  form.value = _cloneDeep(plan);

  isLoading.value = false;

  useRouter().push({
    query: {
      plan: useDialogStore().planEdit.id,
      workspace: useDialogStore().planEdit.workspaceDomain,
    },
  });
};

const onClose = () => {
  form.value = {};

  useRouter().push({
    query: {
      ...useRoute().query,
      plan: undefined,
      workspace: undefined,
    },
  });
};

const savePlan = async() => {
  isSubmitting.value = true;

  const response = await usePlansStore().update({
    planId: form.value._id,
    body: {
      ...form.value,
      workspace: form.value.workspace?.domain,
    },
  });

  isSubmitting.value = false;

  if (response) {
    useToast(useT('bcd138'));
    useDialogStore().planEdit.onSubmit(response);
  }

  return response;
};

const onSubmit = async() => {
  const response = await savePlan();
  if (response) {
    useDialogStore().close('planEdit');
  }
};

const handleDeactivatePlan = () => {
  useModalStore().open({
    type: 'danger',
    title: useT('bcd289'),
    description: useT('bcd290'),
    submitButtonText: useT('bcd291'),
    cancelButtonText: useT('bcd205'),
    onSubmit: async() => {
      isDeactivating.value = true;

      const response = await usePlansStore().deactivate({
        planId: form.value?._id,
        workspaceDomain: form.value?.workspace?.domain,
      });

      isDeactivating.value = false;

      if (response) {
        useToast(useT('bcd292'));
        useDialogStore().planEdit.onSubmit();
        useDialogStore().close('planEdit');
      }
    },
  });
};

// Lifecycle
onMounted(() => {
  if (useRoute()?.query?.plan) {
    useDialogStore().open('planEdit', {
      id: useRoute().query?.plan,
      workspaceDomain: useRoute().query?.workspace,
    });
  }
});
</script>
