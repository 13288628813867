<template>
  <Dialog
    v-model="useDialogStore().planCreate.isOpen"
    size="small-hug"
    @open="onOpen"
    @close="onClose"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd271')"
        :show-close="true"
        @close="close"
      />
    </template>

    <div
      v-if="isLoading"
      v-loading="true"
      class="h-80 w-full"
    />
    <div v-else>
      <Form
        ref="formRef"
        :model="form"
        :rules="rules"
        @submit="onSubmit"
      >
        <InputBlock
          :label="useT('bcd264')"
          prop="type"
        >
          <RadioGroup
            :model-value="form.type"
            :options="[
              { id: 'singleUse', text: useT('bcd245') },
              { id: 'subscription', text: useT('bcd246' ) },
            ]"
            @update:model-value="(value) => _set(form, 'type', value)"
          />
        </InputBlock>
      </Form>
    </div>

    <template
      v-if="!isLoading"
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd272')"
        :text-cancel="useT('bcd205')"
        align="right"
        :loading="isSubmitting"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('planCreate')"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const form = ref({
  type: 'singleUse',
});

const rules = {
};

const isLoading = ref(false);
const isSubmitting = ref(false);
const formRef = ref(null);

// Computed

// Methods
const onOpen = async() => {
};

const onClose = () => {
  form.value = {};
};

const savePlan = async() => {
  isSubmitting.value = true;

  const response = await usePlansStore().create({
    ...form.value,
    workspace: useDialogStore().planCreate.workspaceDomain,
  });

  isSubmitting.value = false;

  if (response) {
    useToast(useT('bcd138'));
    useDialogStore().planCreate.onSubmit(response);
  }

  return response;
};

const onSubmit = async() => {
  const response = await savePlan();
  if (response) {
    const onSubmit = useDialogStore().planCreate.onSubmit; // fetch plans after edit plan from created one

    useDialogStore().close('planCreate');

    useDialogStore().open('planEdit', { id: response._id, workspaceDomain: response.workspace?.domain, onSubmit });
  }
};
</script>
