<template>
  <Dialog
    v-model="useDialogStore().quoteEditTitle.isOpen"
    size="small-hug"
    @open="onOpen"
    @close="onClose"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd306')"
        :show-close="true"
        @close="close"
      />
    </template>

    <div
      v-if="isLoading"
      v-loading="true"
      class="h-32 w-full"
    />
    <div v-else>
      <Form
        ref="formRef"
        :model="form"
        :rules="rules"
        @submit="onSubmit"
      >
        <InputBlock
          :label="useT('bcd278')"
          prop="title"
          class="mb-0"
        >
          <TextInput
            v-model="form.title"
            :placeholder="useT('bcd279')"
          />
        </InputBlock>
      </Form>
    </div>

    <template
      v-if="!isLoading"
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd15')"
        :text-cancel="useT('bcd205')"
        align="left"
        :loading="isSubmitting"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('quoteEditTitle')"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const form = ref({
});

const rules = {
  title: [useFormRules().required],
};

const isLoading = ref(false);
const isSubmitting = ref(false);
const formRef = ref(null);

// Computed

// Methods
const onOpen = async() => {
  isLoading.value = true;

  const quote = await useQuotesStore().fetchQuote({
    quoteId: useDialogStore().quoteEditTitle.id,
    workspaceDomain: useDialogStore().quoteEditTitle.workspaceDomain,
  });

  if (!quote) {
    useDialogStore().close('quoteEditTitle');
    return;
  }

  form.value = _cloneDeep(quote);

  isLoading.value = false;
};

const onClose = () => {
  form.value = {};
  useDialogStore().quoteEditTitle.item = null;

  useRouter().push({
    query: {
      ...useRoute().query,
      quote: undefined,
      workspace: undefined,
    },
  });
};

const saveQuote = async() => {
  isSubmitting.value = true;

  const response = await useQuotesStore().update({
    quoteId: form.value._id,
    body: {
      title: form.value.title,
      workspace: form.value.workspace?.domain,
    },
  });

  isSubmitting.value = false;

  if (response) {
    useToast(useT('bcd138'));
    useDialogStore().quoteEditTitle.onSubmit(response);
  }

  return response;
};

const onSubmit = async() => {
  const response = await saveQuote();
  if (response) {
    useDialogStore().close('quoteEditTitle');
  }
};
</script>
