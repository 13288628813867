<template>
  <div
    class="shadow-sm border border-gray-200 rounded-lg cursor-pointer transition hover:shadow-md"
  >
    <div class="flex items-center w-full p-3">
      <Avatar
        size="md"
        type="square"
        :icon="props.plan.type === 'subscription' ? 'infinity' : 'package'"
        class="border-none"
        :class="props.showStatus && plan.status !== 'active' ? '' : 'bg-green-100 [&_svg]:text-green-500'"
      />
      <div class="flex-1 flex items-center">
        <div class="mx-3 sm:w-40">
          <div class="text-xs text-gray-600">
            {{ props.plan.type === 'singleUse' ? useT('bcd245') : useT('bcd246') }}
          </div>
          <div class="text-sm font-medium text-gray-900">
            {{ _capitalize(props.plan.config.tier) }}
          </div>
        </div>
      </div>
      <div class="flex items-end lg:items-center flex-col lg:flex-row gap-y-1">
        <div
          v-if="props.plan.type === 'subscription'"
          class="lg:w-64 lg:px-3 text-sm text-gray-600 text-right lg:text-left"
        >
          {{ props.plan.config?.billingPeriod === 'monthly' ? useT('bcd293') : (props.plan.config?.billingPeriod === 'annual' ? useT('bcd294') : '') }}
          ({{ useT('bcd295') }}
          {{ getFormattedDate(props.plan.config?.expiringAt, {
            lang: useAuthStore().user?.locale,
            format: 'll',
          }) }})
        </div>
        <div
          class="lg:w-32"
        >
          <Badge
            :text="_upperFirst(plan.status)"
            :type="statusTypeMap[plan.status]"
          />
        </div>
        <div
          class="text-gray-500 text-sm text-right px-3 w-32 truncate"
        >
          {{ useT('bcd296', { value: props.plan.config.maxCapacity }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
});

// Refs
const statusTypeMap = {
  active: 'success',
  expired: 'warning',
  used: 'neutral',
  deactivated: 'critical',
};
</script>
