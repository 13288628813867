<template>
  <Dialog
    v-model="useDialogStore().plans.isOpen"
    size="medium"
    @open="onOpen"
    @close="onClose"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd263')"
        :show-close="true"
        @close="close"
      />
    </template>

    <div
      v-if="isLoading"
      v-loading="true"
      class="h-80 w-full"
    />
    <div v-else>
      <div class="flex items-center justify-between mb-4">
        <h2 class="text-2xl font-semibold">
          {{ useT('bcd263') }}
        </h2>

        <Button
          type="primary"
          icon-left="package-plus"
          @click="useDialogStore().open('planCreate', { workspaceDomain: useDialogStore().plans.workspaceDomain, onSubmit: () => fetchPlans() })"
        >
          {{ useT('bcd272') }}
        </Button>
      </div>

      <div class="space-y-3">
        <PlanItem
          v-for="plan in usePlansStore().plans"
          :key="plan._id"
          :plan="plan"
          @click="useDialogStore().open('planEdit', { id: plan._id, workspaceDomain: useDialogStore().plans.workspaceDomain, onSubmit: () => fetchPlans() })"
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
// Refs
const isLoading = ref(false);

// Computed

// Methods
const fetchPlans = async() => {
  isLoading.value = true;

  await usePlansStore().fetchPlans({
    workspaceDomain: useDialogStore().plans.workspaceDomain,
  });

  isLoading.value = false;
};

const onOpen = async() => {
  await fetchPlans();

  useRouter().push({
    query: {
      workspace: useDialogStore().plans.workspaceDomain,
      showPlans: true,
    },
  });
};

const onClose = () => {
  useRouter().push({
    query: {
      ...useRoute().query,
      showPlans: undefined,
    },
  });
};

// Lifecycle
onMounted(() => {
  if (useRoute()?.query?.showPlans) {
    useDialogStore().open('plans', {
      workspaceDomain: useRoute().query?.workspace,
    });
  }
});
</script>
