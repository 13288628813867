<template>
  <header
    class="transition-all fixed top-0 left-0 w-full z-[1200] border-b border-gray-200 bg-white h-14 px-4 md:px-10"
    :class="{
      'ml-[var(--mobile-navigation-width)] md:ml-0 min-w-max md:min-w-0': !grid.md && useGeneralStore().isBurgerMenuOpen
    }"
  >
    <div class="flex justify-between items-center h-full">
      <div class="flex items-center gap-x-4 h-full">
        <template v-if="grid.md">
          <nuxt-link
            to="/"
            class="py-2"
          >
            <MainLogo
              class="w-auto h-8"
            />
          </nuxt-link>

          <div class="bg-gray-200 h-4 w-px" />
        </template>

        <Button
          class="md:hidden"
          type="regular-text"
          icon-left="menu-01"
          @click="useGeneralStore().isBurgerMenuOpen = !useGeneralStore().isBurgerMenuOpen"
        />
        <MainNavigation class="hidden md:flex items-center gap-x-3 h-full" />
      </div>

      <div class="flex items-center gap-4">
        <MainHeaderUser />
      </div>
    </div>
  </header>
</template>
