<template>
  <InputBlock
    :label="useT('bcd311')"
    prop="pipedrive.deal"
    :direction="props.direction"
  >
    <Select
      v-loading="isPipedriveDealsLoading"
      :model-value="form.pipedrive?.deal"
      :options="pipedriveDeals"
      filterable
      @update:model-value="(value) => _set(form, 'pipedrive.deal', value)"
    />
  </InputBlock>
</template>

<script setup>
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  direction: {
    type: String,
    default: 'vertical',
  },
});

const emit = defineEmits(['update:form']);

const form = useVModel(props, 'form', emit);

// Refs
const pipedriveDeals = ref([]);
const isPipedriveDealsLoading = ref(false);

onMounted(async() => {
  isPipedriveDealsLoading.value = true;
  pipedriveDeals.value = await useQuotesStore().getPipedriveDeals();
  isPipedriveDealsLoading.value = false;
});
</script>
