<template>
  <div>
    <InputBlock
      :label="useT('bcd253')"
      prop="product.category"
      direction="horizontal"
    >
      <ToggleButtons
        :model-value="form.product?.category || 'planSingle'"
        :options="[
          { id: 'planSingleUse', text: useT('bcd245') },
          { id: 'planSubscription', text: useT('bcd246') },
          { id: 'addon', text: useT('bcd280') },
        ]"
        class="w-full"
        @update:model-value="(value) => _set(form, 'product.category', value)"
      />
    </InputBlock>

    <template v-if="form.product?.category === 'planSingleUse'">
      <InputBlock
        :label="useT('bcd249')"
        prop="singleUseTier"
        direction="horizontal"
      >
        <Select
          v-model="singleUseTier"
          :options="[
            { id: 'basic', text: useT('bcd250') },
            { id: 'professional', text: useT('bcd251') },
            { id: 'enterprise', text: useT('bcd252') },
          ]"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd266')"
        prop="product.data.maxCapacity"
        direction="horizontal"
      >
        <NumberInput
          :model-value="form.product?.data?.maxCapacity || 0"
          class="w-full"
          :min="0"
          @update:model-value="(value) => _set(form, 'product.data.maxCapacity', value)"
        />
      </InputBlock>
    </template>

    <template v-if="form.product?.category === 'planSubscription'">
      <InputBlock
        :label="useT('bcd271')"
        prop="product.key"
        direction="horizontal"
      >
        <Select
          :model-value="form.product?.key"
          :options="subscriptionTiers[subscriptionBillingPeriod]"
          @update:model-value="(value) => _set(form, 'product.key', value)"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd254')"
        prop="subscriptionBillingPeriod"
        direction="horizontal"
      >
        <ToggleButtons
          v-model="subscriptionBillingPeriod"
          :options="[
            { id: 'monthly', text: useT('bcd255') },
            { id: 'annual', text: useT('bcd256') },
          ]"
          class="w-full"
        />
      </InputBlock>
    </template>

    <template v-if="form.product?.category === 'addon'">
      <InputBlock
        :label="useT('bcd257')"
        prop="product.key"
        direction="horizontal"
      >
        <Select
          :model-value="form.product?.key"
          :options="[
            { id: 'addon_dedicated_support_monthly', text: useT('bcd258') },
          ]"
          @update:model-value="(value) => _set(form, 'product.key', value)"
        />
      </InputBlock>
    </template>

    <InputBlock
      :label="useT('bcd299')"
      prop="discount"
      direction="horizontal"
    >
      <Select
        :model-value="form.discount"
        :options="[
          { id: 'tenPercent', text: '10%' },
          { id: 'twentyPercent', text: '20%' },
        ]"
        clearable
        @update:model-value="(value) => _set(form, 'discount', value)"
      />
    </InputBlock>

    <InputBlock
      :label="useT('bcd300')"
      prop="price"
      direction="horizontal"
    >
      <div
        v-if="form.product"
        class="py-1.5"
      >
        <div
          v-if="form.discount"
          class="text-gray-900 text-sm line-through"
        >
          {{ isNaN(totalPriceWithoutDiscount) ? '-' : useFormatPrice(totalPriceWithoutDiscount, currency) }}
        </div>
        <div class="text-gray-900 text-base font-semibold">
          {{ isNaN(totalPrice) ? '-' : useFormatPrice(totalPrice, currency) }}
        </div>
      </div>
    </InputBlock>
  </div>
</template>

<script setup>
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  workspace: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['update:form']);

const form = useVModel(props, 'form', emit);

// Refs
const subscriptionTiers = {
  annual: [
    { id: 'subscription_basic_200_annual', text: useT('subscription_basic_200') },
    { id: 'subscription_basic_500_annual', text: useT('subscription_basic_500') },
    { id: 'subscription_basic_unlimited_annual', text: useT('subscription_basic_unlimited') },
    { id: 'subscription_professional_200_annual', text: useT('subscription_professional_200') },
    { id: 'subscription_professional_500_annual', text: useT('subscription_professional_500') },
    { id: 'subscription_professional_unlimited_annual', text: useT('subscription_professional_unlimited') },
    { id: 'subscription_enterprise_200_annual', text: useT('subscription_enterprise_200') },
    { id: 'subscription_enterprise_500_annual', text: useT('subscription_enterprise_500') },
    { id: 'subscription_enterprise_unlimited_annual', text: useT('subscription_enterprise_unlimited') },
  ],
  monthly: [
    { id: 'subscription_basic_200_monthly', text: useT('subscription_basic_200') },
    { id: 'subscription_basic_500_monthly', text: useT('subscription_basic_500') },
    { id: 'subscription_basic_unlimited_monthly', text: useT('subscription_basic_unlimited') },
    { id: 'subscription_professional_200_monthly', text: useT('subscription_professional_200') },
    { id: 'subscription_professional_500_monthly', text: useT('subscription_professional_500') },
    { id: 'subscription_professional_unlimited_monthly', text: useT('subscription_professional_unlimited') },
    { id: 'subscription_enterprise_200_monthly', text: useT('subscription_enterprise_200') },
    { id: 'subscription_enterprise_500_monthly', text: useT('subscription_enterprise_500') },
    { id: 'subscription_enterprise_unlimited_monthly', text: useT('subscription_enterprise_unlimited') },
  ],
};

// Computed
const currency = computed(() => props.workspace?.stripe?.isCzechAccount ? 'czk' : 'usd');

const singleUseTier = computed({
  get: () => props.form?.product?.key?.replace('single_use_', ''),
  set: (value) => {
    _set(form.value, 'product.key', `single_use_${value}`);
    _set(form.value, 'product.data.tier', value);
  },
});

const subscriptionBillingPeriod = computed({
  get: () => props.form?.product?.key?.includes('_monthly') ? 'monthly' : (props.form?.product?.key?.includes('_annual') ? 'annual' : ''),
  set: (value) => {
    const productKey = form.value.product.key?.replace('_monthly', '').replace('_annual', '');
    const isProductKeyInTiers = subscriptionTiers[value].some((tier) => tier.id === `${productKey}_${value}`);

    _set(form.value, 'product.key', isProductKeyInTiers ? `${productKey}_${value}` : '');
  },
});

const totalPriceWithoutDiscount = computed(() => getProductPriceAmount({
  price: useProductsStore().prices?.[currency.value]?.[form.value?.product?.key],
  quote: form.value,
}));

const totalPrice = computed(() => getProductPriceAmount({
  price: useProductsStore().prices?.[currency.value]?.[form.value?.product?.key],
  quote: form.value,
  discount: form.value.discount,
}));

// Watch
watch(() => form.value.product?.category, (value) => {
  if (value === 'planSingleUse') {
    form.value = _set(form.value, 'product.key', 'single_use_basic');
    form.value = _set(form.value, 'product.data', {
      tier: 'basic',
      maxCapacity: 200,
    });
  } else if (value === 'planSubscription') {
    form.value = _set(form.value, 'product.key', 'subscription_basic_200_monthly');
    form.value = _set(form.value, 'product.data', {});
  } else if (value === 'addon') {
    form.value = _set(form.value, 'product.key', 'addon_dedicated_support_monthly');
    form.value = _set(form.value, 'product.data', {
      name: 'dedicatedSupport',
    });
  }
});
</script>
