<template>
  <div>
    <Dialog
      v-model="useDialogStore().settings.isOpen"
      size="large"
      unbounded
    >
      <div class="flex h-full">
        <div class="overflow-auto h-full hidden md:block">
          <Navigation
            :model-value="useDialogStore().settings.currentTab"
            class="bg-gray-100 border-r p-2 flex-shrink-0 w-60 min-h-full"
            :sections="sections"
            @update:model-value="(payload) => useDialogStore().settings.currentTab = payload"
          />
        </div>
        <div class="flex-1">
          <MainSettingsDialogPersonal
            v-if="useDialogStore().settings.currentTab === 'personal'"
          />
          <MainSettingsDialogUsers
            v-if="useDialogStore().settings.currentTab === 'users'"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
// Refs
const sections = ref([
  {
    title: useT('bcd5'),
    items: [
      {
        id: 'personal',
        title: useAuthStore()?.user?.fullName || useAuthStore()?.user?.email,
        icon: 'user-circle',
      },
    ],
  },
]);

// Lifecycle
onBeforeMount(() => {
  if (useAuthStore().hasRole(['admin'])) {
    sections.value.push({
      title: useT('bcd6'),
      items: [
        {
          id: 'users',
          title: useT('bcd8'),
          icon: 'users-02',
        },
      ],
    });
  }
});
</script>
