<template>
  <ActionList
    :items="[
      {
        id: 'personal',
        title: useAuthStore()?.user?.fullName || useAuthStore()?.user?.email,
        icon: 'user-circle',
      },
      useAuthStore().hasRole(['admin']) ? {
        id: 'users',
        title: useT('bcd8'),
        icon: 'users-02',
      } : undefined,
      {
        id: 'logout',
        title: useT('bcd14'),
        icon: 'log-out-04',
      }].filter(Boolean)"
    placement="bottom-end"
    class="popper-mobile-bottom"
    @click="onClick"
  >
    <button
      class="flex items-center gap-1"
      data-cy="open-dropdown-user"
    >
      <Avatar
        :image="useAuthStore()?.user?.profilePhoto"
        :name="useAuthStore()?.user?.fullName"
      />
    </button>
  </ActionList>
</template>

<script setup>
// Methods
const onClick = (id) => {
  if (id === 'logout') {
    useAuthStore().logout();
    return;
  }

  useDialogStore().open('settings', { currentTab: id });
};
</script>
