<template>
  <div class="h-full relative">
    <DialogHeader
      :title="useAuthStore()?.user?.fullName"
      size="large"
      show-close
      class="absolute top-0 left-0 z-20 bg-white w-full rounded-tr-[var(--el-dialog-border-radius)]"
      @close="useDialogStore().close('settings')"
    />
    <div class="h-full overflow-auto">
      <div class="py-16">
        <Form
          ref="formRef"
          :model="form"
          :rules="rules"
          @submit="onSubmit"
        >
          <div class="p-6">
            <div class="flex gap-5 border-b border-gray-200">
              <div class="flex-1">
                <InputBlock
                  :label="useT('bcd9')"
                  prop="fullName"
                >
                  <TextInput
                    v-model="form.fullName"
                    name="fullName"
                  />
                </InputBlock>
                <InputBlock
                  :label="useT('bcd206')"
                  prop="email"
                >
                  <TextInput
                    v-model="form.email"
                    type="email"
                    name="email"
                    disabled
                  />
                </InputBlock>
              </div>
            </div>

            <div class="py-3 border-b border-gray-200">
              <InputBlock
                direction="horizontal"
                :label="useT('bcd10')"
                label-stretch
                class="mb-0"
              >
                <Button
                  type="regular-outline"
                  data-cy="change-password-button"
                  @click="useDialogStore().open('passwordChange')"
                >
                  {{ useT('bcd11') }}
                </Button>
              </InputBlock>
            </div>

            <div class="py-4">
              <Button
                type="regular-text"
                icon-left="log-out-04"
                @click="useAuthStore().logout()"
              >
                {{ useT('bcd14') }}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>

    <div class="border-t px-6 py-5 absolute left-0 bottom-0 w-full bg-white">
      <ButtonGroup
        type="primary"
        :text-submit="useT('bcd15')"
        :text-cancel="useT('bcd214')"
        :loading="isSubmitting"
        @cancel="useDialogStore().close('settings')"
        @submit="formRef.onSubmit()"
      />
    </div>
  </div>
</template>

<script setup>
// Refs
const formRef = ref(null);
const form = ref(_cloneDeep(useAuthStore().user));
const isSubmitting = ref(false);

const rules = {
  fullName: [useFormRules().required],
};

// Methods
const updateMe = async(body) => {
  const response = await useUsersStore().updateUserMe(body);

  if (response) {
    form.value = {
      ...form.value,
      ...body,
    };

    useAuthStore().fetchUser();

    return response;
  }
};

const onSubmit = async() => {
  isSubmitting.value = true;

  const data = await updateMe({
    fullName: form.value.fullName,
  });

  isSubmitting.value = false;

  if (data) {
    useDialogStore().close('settings');
  }
};
</script>
